import {apiRequest} from './util';

export function fetchToolboxCategories() {
    return apiRequest({path: `/ch/categories?type=TOOLBOX`, method: "GET"});
}

export function fetchToolboxesForCategory(id, ctx) {
    return apiRequest({path: `/ch/toolboxes?category_id=${id}`, method: "GET", ctx});
}

export function fetchToolboxesForExpertise(id, ctx) {
    return apiRequest({path: `/ch/toolboxes?expertise_id=${id}`, method: "GET", ctx});
}

export function fetchToolboxFile({toolId, fileId}) {
    return apiRequest({path: `/ch/tool/${toolId}/file/${fileId}`, method: "GET", responseFormat: 'blob'});
}

export function fetchToolboxesAllFiles({toolId}) {
    return apiRequest({path: `/ch/tool/${toolId}/files`, method: "GET", responseFormat: 'blob'});
}

export function fetchToolboxChildComments(id, commentId) {
    return apiRequest({path: `/ch/toolbox/${id}/childcomments?parent_id=${commentId}`, method: "GET"});
}

export function fetchToolChildComments(id, commentId) {
    return apiRequest({path: `/ch/tool/${id}/childcomments?parent_id=${commentId}`, method: "GET"});
}

export function commentToolbox(id, content, parentId) {
    return apiRequest({path: `/ch/toolbox/${id}/comment`, method: "POST", data: {content, parent_id: parentId}});
}

export function commentToolboxTool(id, content, parentId) {
    return apiRequest({path: `/ch/tool/${id}/comment`, method: "POST", data: {content, parent_id: parentId}});
}
