import React, {useEffect, useState} from  'react';
import ReactGA from 'react-ga';
import router from 'next/router';
import {useAuth} from '../util/auth';
import {TrackingID} from '../config';

const TrackingContext = React.createContext();

function TrackingProvider(props) {
    const auth = useAuth();

    const [analytics, setAnalytics] = useState({
        isInitialized: false,
        hasUser: false,
        trackers: ['mainTracker']
    })

    const handleRouteChange = url  => {
        ReactGA.set({ page:  url }, analytics.trackers);
        ReactGA.pageview(url, analytics.trackers);
    };

    const addTracker = (trackerId, trackerName) => {
        if (analytics.isInitialized) {
            ReactGA.addTrackers([
                {
                    trackingId:  trackerId,
                    gaOptions: {
                        name:  trackerName
                    }
                }
            ]);
            setAnalytics((prev) => ({
                ...prev,
                trackers: [...prev.trackers, trackerName]
            }))
        }
    }
    const removeTracker = (trackerName) => {
        if (analytics.isInitialized) {
            setAnalytics((prev) => ({
                ...prev,
                trackers: prev.trackers.filter((tracker) => tracker !== trackerName)
            }))
        }
    }

    const logEvent = ({ category = '', action = '', label = '' }) => {
        if (analytics.isInitialized) {
            ReactGA.event({
                category,
                action,
                label
            }, analytics.trackers)
        }
    }

    const user = auth.user;

    useEffect(() => {
        const { isInitialized, hasUser, trackers } = analytics
        const userId = user ? user.id : undefined;

        router.events.on('routeChangeComplete', handleRouteChange);

        if (!isInitialized) {
            ReactGA.initialize(TrackingID, {
                gaOptions: {
                    userId: userId
                }
            });

            setAnalytics(prev  => ({
                ...prev,
                isInitialized:  true,
                hasUser: Boolean(userId)
            }));

            handleRouteChange(window.location.pathname + window.location.search);
        } else if (isInitialized && !hasUser) {
            ReactGA.set({ userId }, trackers)

            setAnalytics(prev  => ({
                ...prev,
                hasUser: Boolean(userId)
            }));
        }

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        }
    }, [user])

    return <TrackingContext.Provider value={{ addTracker, removeTracker, logEvent }} {...props} />
}

const useTracking = () =>  React.useContext(TrackingContext);

export { TrackingProvider, useTracking };
