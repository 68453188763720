import { combineReducers } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
import _ from 'lodash';

import casesReducer from '../features/casesSlice';
import usersReducer from '../features/usersSlice';
import categoriesReducer from '../features/categoriesSlice';
import expertisesReducer from '../features/expertisesSlice';
import commentsReducer from '../features/commentsSlice';
import companiesReducer from '../features/companiesSlice';
import toolboxesReducer from '../features/toolboxSlice';
import toolsReducer from '../features/toolsSlice';
import videosReducer from '../features/videosSlice';
import keyWindowsReducer from '../features/keyWindowsSlice';
import timezoneReducer from '../features/timezoneSlice';


const customizer = (objValue, srcValue) => {
    if (_.isArray(objValue)) {
        return _.union(objValue, srcValue);
    }
}

const rootReducer = (state, action) => {
    switch (action.type) {
        case HYDRATE:
            
            // return {
            //     // server state
            //     ...action.payload,
            //     // clint state
            //     expertises: state.expertises
            // };
            
            const mergedState = _.mergeWith({},
                state,
                action.payload,
                customizer
            );
            
            return {
                ...mergedState,
                keyWindows: state.keyWindows,
                timezone: state.timezone,
            };
        default: {
            const combineReducer = combineReducers({
                cases: casesReducer,
                users: usersReducer,
                categories: categoriesReducer,
                expertises: expertisesReducer,
                comments: commentsReducer,
                companies: companiesReducer,
                toolboxes: toolboxesReducer,
                tools: toolsReducer,
                videos: videosReducer,
                keyWindows: keyWindowsReducer,
                timezone: timezoneReducer,
            });
            return combineReducer(state, action);
        }
    }
};

export default rootReducer;
