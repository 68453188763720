import React from "react";

function PageLoader(props) {
  const { children, ...otherProps } = props;

  return (
    <div className="h-[400px] flex justify-content items-center px-2" {...otherProps}>
      {!props.children && <div className={`h-[20px] w-[20px] is-loading`} />}

      {props.children && <>{props.children}</>}
    </div>
  );
}

export default PageLoader;
