import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {fetchCasesByStatus, fetchCasesById, fetchToolboxCategories} from './thunks';

const categoriesAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = categoriesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCasesByStatus.fulfilled]: (state, action) => {
            if (action.payload.categories) {
                categoriesAdapter.upsertMany(state, action.payload.categories);
            }
        },
        [fetchCasesById.fulfilled]: (state, action) => {
            if (action.payload.categories) {
                categoriesAdapter.upsertMany(state, action.payload.categories);
            }
        },
        [fetchToolboxCategories.fulfilled]: (state, action) => {
            if (action.payload.categories) {
                categoriesAdapter.upsertMany(state, action.payload.categories);
            }
        }
    },
});

export const {
    selectAll: selectAllCategories,
    selectById: selectCategoryById,
    selectIds: selectCategoryIds,
    selectEntities: selectCategoryEntities,
} = categoriesAdapter.getSelectors((state) => state.categories);

export default categoriesSlice.reducer
