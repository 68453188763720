import { useCallback, useEffect, useRef } from 'react';

// @todo: revisit this issue (it might be that better option exists)
// https://github.com/facebook/react/issues/14099
export const useStableCallback = <T extends (...args: any[]) => any>(callback: T) => {
    const ref = useRef<T>(callback);

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    return useCallback((...args: Parameters<T>): ReturnType<T> => {
        return ref.current(...args);
    }, []);
}

