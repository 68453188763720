import React from "react";
import cn from 'clsx';
import {Linkedin} from 'lucide-react';
import Link from 'next/link';
import {Icons} from '@/components/icons';

type FooterProps = {
    links1?: {
        title: string;
        path: string;
    }[];
    links2?: {
        title: string;
        path: string;
    }[];
    links3?: {
        title: string;
        path: string;
    }[];
    links4?: {
        title: string;
        path: string;
    }[];
    links5?: {
        title: string;
        path: string;
    }[];
    socialLinks?: {
        name: string;
        link: string;
    }[];
    contacts: {
        name: string;
        link: string;
        title: string;
        phone: string;
        address: string;
        email: string;
    };
    className?: string;
}
function Footer(props: FooterProps) {
    return (
        <footer className="bg-footer text-footer-foreground">
            <div className="container">
                <div className="mx-auto pt-8 w-full">
                    <div className="md:flex md:justify-between">
                        <div className="mb-6 md:mb-0">
                            <Link href="/">
                                <a className="mr-6 flex items-center space-x-2">
                                    <Icons.logo className="w-auto h-14"/>
                                </a>
                            </Link>
                        </div>
                        <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                            <div>
                                <h2 className="mb-4 text-sm font-bold uppercase text-footer-foreground">Resources</h2>
                                <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                    {
                                        [
                                            ...(props.links1 || []),
                                            ...(props.links2 || []),
                                            ...(props.links3 || []),
                                        ].map((it, i, arr) => (
                                            <li className={cn({
                                                'mb-4': i !== arr.length - 1,
                                            })} key={it.path + it.title}>
                                                <FooterLink href={it.path}>
                                                    {it.title}
                                                </FooterLink>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <div>
                                <h2 className="mb-4 text-sm font-bold uppercase text-footer-foreground">Legal</h2>
                                <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                    {
                                        [
                                            ...(props.links4 || []),
                                            ...(props.links5 || []),
                                        ].map((it, i, arr) => (
                                            <li className={cn({
                                                'mb-4': i !== arr.length - 1,
                                            })} key={it.path + it.title}>
                                                <FooterLink href={it.path}>
                                                    {it.title}
                                                </FooterLink>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div>
                                    <h2 className="mb-4 text-sm font-bold uppercase text-footer-foreground">Follow us</h2>
                                    <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                        {(props.socialLinks && props.socialLinks.length) ? (
                                            props.socialLinks.map((it) => (
                                                <li className="mb-4" key={it.name}>
                                                    <FooterLink href={it.link}>
                                                        {it.name}
                                                    </FooterLink>
                                                </li>
                                            ))
                                        ) : null}
                                    </ul>
                                </div>

                                {!!props.contacts && (
                                    <div>
                                        <h2 className="mb-4 text-sm font-bold uppercase text-footer-foreground">{props.contacts.title || 'Contact'}</h2>
                                        <ul className="text-gray-600 dark:text-gray-400 font-medium">
                                            <li className="mb-4">
                                                <span>{props.contacts.address}</span>
                                            </li>
                                            <li className="mb-4">
                                                <FooterLink href={`mailto:${props.contacts.email}`}>
                                                    {props.contacts.email}
                                                </FooterLink>
                                            </li>
                                            <li>
                                                <FooterLink href={`tel:${props.contacts.phone}`}>
                                                    {props.contacts.phone}
                                                </FooterLink>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <hr className="mt-10 border-accent sm:mx-auto"/>
                    <div className="sm:flex sm:items-center sm:justify-between py-4">
                      <span className="text-sm text-footer-foreground sm:text-center">Copyright ©<a
                          href="https://casehub.pro/" className="hover:underline">Casehub</a>. All Rights Reserved.
                      </span>
                        <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                            {(props.socialLinks && props.socialLinks.length) ? (
                                props.socialLinks.map((it) => {
                                    return (
                                        <a href={it.link} key={it.link + it.name}>
                                            {(it.name || '').toLowerCase() === 'linkedin' ? (
                                                <Linkedin className="w-5 h-5"/>
                                            ) : null}
                                            <span className="sr-only">{it.name}</span>
                                        </a>
                                    );
                                })
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

type FooterLinkProps = {
    href: string;
    children: React.ReactNode;
}
const FooterLink = (props: FooterLinkProps) => {
    return (
        <Link passHref href={props.href}>
            <a className="hover:underline text-footer-foreground text-sm">
                {props.children}
            </a>
        </Link>
    )
}

export default Footer;
