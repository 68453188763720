import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {fetchExpertises} from './thunks';

const expertisesAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = expertisesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const expertisesSlice = createSlice({
    name: 'expertises',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [fetchExpertises.fulfilled]: (state, action) => {
            if (action.payload.expertises) {
                expertisesAdapter.upsertMany(state, action.payload.expertises);
            }
        },
    },
});

export const {
    selectAll: selectAllExpertises,
    selectById: selectExpertiseById,
    selectIds: selectExpertiseIds,
    selectEntities: selectExpertiseEntities,
} = expertisesAdapter.getSelectors((state) => state.expertises);

export default expertisesSlice.reducer
