export enum CaseStatuses {
    NEW = 'NEW',
    CLOSED = 'CLOSED',
    SCHEDULED = 'SCHEDULED',
    TENTATIVE = 'TENTATIVE',
    CLOSED_PRIVATE = 'CLOSED_PRIVATE'
}

export const DEFAULT_SESSION_DURATION_IN_MINUTES = 90;


export const PaymentPlanFeatures = {
    read_and_comment: 'read_and_comment',
    toolbox: 'toolbox',
    sessions_videos: 'sessions_videos',
    toolbox_download: 'toolbox_download',
    add_cases: 'add_cases',
    live_sessions: 'live_sessions',
    coaching_sessions: 'coaching_sessions'
}

export enum PaymentActivationStatuses {
    PENDING = 0,
    SUCCESS = 1,
    FAILURE = -1,
}

export const MINIMUM_FEEDBACK_INVITATIONS = 5;
