import Cookie from 'js-cookie';

const tzValidationMemo = {};
const isValidTz = (tz) => {
    try {
        if (tzValidationMemo[tz] === undefined) {
            Intl.DateTimeFormat(undefined, { timeZone: tz });
            tzValidationMemo[tz] = true;
        }
    } catch (err) {
        tzValidationMemo[tz] = false;
    }

    return tzValidationMemo[tz];
};

export const getDefaultTimezone = () => {
    const fromCookie = Cookie.get('timezone');
    const defaultTz = 'Etc/UTC';
    let tz = fromCookie;

    if (!fromCookie) {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return isValidTz(tz) ? tz : defaultTz;
}

export const setUserTimezone = (tz) => {
    Cookie.set('timezone', tz, { expires: 360 })
}

export const removeUserTimezone = () => {
    Cookie.remove('timezone')
}

