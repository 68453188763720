import {authCookieName, server} from '../config';
import {isNode} from './checks';
import router from 'next/router';
import Cookies from 'cookies';
import {fetchCurrentUser} from './users';
import FormData from 'form-data';

export function buildApiUrl(path: string) {
    if (!isNode) {
        return `/api${path}`
    }

    return `${server}/api${path}`;
}

export const getCookiesFromContext = (ctx: {
    req?: any,
    res?: any
}) => {
    if (process.browser || !ctx?.req) {
        return;
    }

    const cookies = new Cookies(ctx.req, ctx.res);
    const authToken = cookies.get(authCookieName);

    return authToken ? {
        [authCookieName]: authToken
    } : undefined;
}

type ApiRequestOptions = {
    path: string,
    method?: string,
    data?: any,
    rawData?: boolean,
    allowRedirect?: boolean,
    responseFormat?: 'json' | 'text' | 'blob',
    ctx?: any
}

export function apiRequest({
    path,
    method = "GET",
    data,
    rawData = false,
    allowRedirect = true,
    responseFormat = 'json',
    ctx
}: ApiRequestOptions) {
    let body;

    if (['POST', 'PUT'].includes(method) && !rawData) {
        const formData = new FormData();
        for (const name in data) {
            if (data[name] !== undefined) {
                if (Array.isArray(data[name])) {
                    data[name].forEach((item: {
                        [key: string]: any
                    }, idx: number) => {
                        if (typeof item === 'object') {
                            Object.entries(item).forEach(([key, value]) => {
                                formData.append(`${name}[${idx}][${key}]`, value);
                            })
                        } else {
                            formData.append(name + '[]', item);
                        }
                    })
                } else {
                    formData.append(name, data[name]);
                }
            }
        }
        body = formData;
    }

    if (['PUT', 'POST'].includes(method) && rawData) {
        body = data
    }

    let url = buildApiUrl(path);
    let cookies = getCookiesFromContext(ctx);

    const headers: {
        [key: string]: string
    } = {};

    if (cookies?.[authCookieName]) {
        headers.cookie = `${authCookieName}=${cookies?.[authCookieName]};`;
    }

    return fetch(url, {
        method: method,
        credentials: 'same-origin',
        body: body,
        headers: headers
    })
    .then(async (response) => {
        let text;
        if (response.status >= 400) {
            try {
                text = await response.text();

            } catch (err) {}
        }

        if (method === 'GET' && response.status === 403 && allowRedirect && process.browser) {
            let redirectPath;

            if (text === 'You have no access to this content, please select a plan!') {
                redirectPath = '/pricing'
            } else {
                redirectPath = `/auth/signin?next=${encodeURIComponent(router.asPath)}`;
            }

            await router.replace(redirectPath);
            return response;
        }

        if (response.status >= 400) {
            throw new CustomError(response.status, text || response.statusText, text);
        }

        if (responseFormat === 'blob') {
            return response.blob();
        }

        if (responseFormat === 'text') {
            return response.text();
        }

        if (responseFormat !== 'json') {
            return response;
        }

        let json = {};

        try {
            json = await response.json();
        } catch (err) {
            console.error(err)
        }

        return json;
    });
}

export class CustomError extends Error {
    code: string | number;
    text?: string;

    constructor(code: string | number, message: string, text?: string) {
        super(message);
        this.code = code;
        if (text) {
            this.text = text;
        }
    }
}

export const forceInternalPath = (afterAuthPath?: string) => {
    if (typeof afterAuthPath !== 'string') {
        return '/';
    }

    if (afterAuthPath.slice(0, 1) !== '/') {
        return `/${afterAuthPath}`;
    }

    return afterAuthPath;
}

export const withUserGetServerSideProps = (fn) => {
    return async (ctx, ...restProps) => {
        let user = null;

        if (!process.browser) {
            const cookies = getCookiesFromContext(ctx);
            const authToken = cookies?.[authCookieName];

            if (authToken) {
                try {
                    user = await fetchCurrentUser(ctx);
                } catch (err) {
                    console.log('error fetchCurrentUser', err);
                    user = null;
                }
            }
        }

        const result = await fn({
            ...ctx,
            user: user,
        }, ...restProps);

        return {
            ...result,
            props: {
                ...result.props,
                user
            }
        }
    }
}

export function downloadFile(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
}
