import {useEffect} from 'react';
import {useRouter} from 'next/router';

const devMode = process.env.NODE_ENV === 'development';

declare global {
    interface Window {
        _mfq: any;
    }
}

export const MouseFlow = () => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChangeComplete = () => {
            if (window._mfq) {
                window._mfq.push(['newPageView', `${location.pathname}${window.location.search}`]);
            }
        }

        if (!window._mfq && !devMode) {
            window._mfq = window._mfq || [];
            (function() {
                const mf = document.createElement("script");
                mf.type = "text/javascript"; mf.defer = true;
                mf.src = "//cdn.mouseflow.com/projects/29ee90f4-7c14-40c5-85e5-3c8b00e8ec84.js";
                document.getElementsByTagName("head")[0].appendChild(mf);
            })();
        }

        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        }
    }, [router]);


    return null;
}
