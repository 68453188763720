import {createDraftSafeSelector, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    fetchToolboxesForCategory,
    fetchToolboxesForExpertise,
    commentToolbox
} from './thunks';

const toolboxesAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = toolboxesAdapter.getInitialState({});

const toolboxesSlice = createSlice({
    name: 'toolboxes',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [fetchToolboxesForCategory.fulfilled]: (state, action) => {
            if (action.payload.toolboxes) {
                toolboxesAdapter.upsertMany(state, action.payload.toolboxes)
            }
        },
        [fetchToolboxesForExpertise.fulfilled]: (state, action) => {
            if (action.payload.toolboxes) {
                toolboxesAdapter.upsertMany(state, action.payload.toolboxes)
            }
        },
        [commentToolbox.fulfilled]: (state, action) => {
            const { toolboxId } = action.meta.arg;
            const commentId = action.payload.id;
            const existingToolbox = state.entities[toolboxId];
            const existingComments = existingToolbox.comments || [];

            if (commentId) {
                existingComments.push(commentId);
                toolboxesAdapter.updateOne(state, {
                    id: toolboxId,
                    changes: {
                        comments: existingComments,
                        comment_count: (existingToolbox.comment_count || 0) + 1
                    }
                })
            }
        },
    },
});

export const {
    selectAll: selectAllToolboxes,
    selectById: selectToolboxById,
    selectIds: selectToolboxIds,
    selectEntities: selectToolboxEntities,
} = toolboxesAdapter.getSelectors((state) => state.toolboxes);

export const makeGetToolboxIdsByCategory = (categoryId) => createDraftSafeSelector([
    selectAllToolboxes
], (all) => {
    return all.filter(it => it.categories.includes(categoryId)).map(it => it.id)
});

export default toolboxesSlice.reducer
