import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {setCurrentUser} from './usersSlice';

const companiesAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = companiesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const companiesSlice = createSlice({
    name: 'companies',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [setCurrentUser]: (state, action) => {
            if (action.payload.company) {
                companiesAdapter.upsertOne(state, action.payload.company);
            }
        },
    },
});

export default companiesSlice.reducer
