import {apiRequest} from './util';

export const fetchProducts = async () => {
    return await apiRequest({path: `/ch/subscription/products`});
}

export const subscribeToProduct = async (productId, {replace}) => {
    return await apiRequest({
        path: `/ch/subscription/create`,
        method: 'POST',
        data: {
            product_id: productId,
            replace: replace
        }
    });
}

export const activateSubscription = async (paymentId) => {
    return await apiRequest({
        path: `/ch/subscription/activate`,
        method: 'POST',
        data: {
            payment_id: paymentId
        }
    });
}

export const cancelSubscription = async (subscriptionId) => {
    return await apiRequest({
        path: `/ch/subscription/cancel`,
        method: 'POST',
        data: {
            subscription_id: subscriptionId
        }
    });
}

export const activateTrialSubscription = async (ctx) => {
    return await apiRequest({
        path: `/ch/subscription/activatetrial`,
        method: 'POST',
        ctx
    });
}


