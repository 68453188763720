import {apiRequest} from './util';

export function fetchAssessmentQuestions() {
    return apiRequest({path: `/ch/expertises/questions`, method: "GET"});
}

export function fetchFeedbackQuestions() {
    return apiRequest({path: `/ch/expertises/fbquestions`, method: "GET"});
}

export const fetchFeedbackRequest = async (feedbackId) => {
    return await apiRequest({path: `/ch/user/${feedbackId}/feedbackrequest`, method: 'GET'});
}

export const postFeedbackAssessment = async (feedbackId, assessmentFormData, extraQuestions) => {
    return await apiRequest({path: `/ch/user/${feedbackId}/feedback`, method: 'POST', data: {
            assessment_form: assessmentFormData,
            ...extraQuestions
        }});
}

export const postSelfAssessment = async (formData) => {
    return await apiRequest({path: `/ch/user/me/assessment`, method: 'POST', data: {
        assessment_form: formData
    }});
}

export const fetchFeedbacks = async () => {
    return await apiRequest({path: `/ch/user/me/feedbacks`, method: 'GET'});
}

export const postCalculateSelfAssessment = async (formData) => {
    return await apiRequest({path: `/ch/expertises/calculate`, method: 'POST', data: {
            assessment_form: formData
        }});
}

export const postExpertises = async (formData) => {
    return await apiRequest({path: `/ch/user/me/expertises`, method: 'POST', data: {
            expertise: formData
        }});
}

export function fetchExpertises() {
    return apiRequest({path: `/ch/expertises`, method: "GET"});
}

export function fetchSelfAssessment() {
    return apiRequest({path: `/ch/user/me/assessments`, method: "GET"});
}

export const markResourceAsCompleted = async (id) => {
    return await apiRequest({path: `/ch/expertises/resourcecompleted`, method: 'POST', data: {
            resource_id: id
        }});
}

export const toggleResourceCompleted = async (id) => {
    return await apiRequest({path: `/ch/expertises/resourcetoggle`, method: 'POST', data: {
            resource_id: id
        }});
}

export const fetchTrainingResources = async (formData) => {
    const query = Object.entries(formData).map(([k, v]) => {
        return `expertises[${k}]=${v}`;
    }).join('&')

    return await apiRequest({path: `/ch/expertises/content?${query}`, method: 'GET'});
}

