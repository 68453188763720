import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    fetchCasesByStatus
} from './thunks';

const keyWindowsAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = keyWindowsAdapter.getInitialState({});

const keyWindowsSlice = createSlice({
    name: 'keyWindows',
    initialState: initialState,
    reducers: {
        setOffset: (state, action) => {
            keyWindowsAdapter.updateOne(state, {
                id: action.payload.id,
                changes: {
                    offset: action.payload.offset
                }
            });
        },
        removeAll: (state, action) => {
            keyWindowsAdapter.removeAll(state);
        },
    },
    extraReducers: {
        [fetchCasesByStatus.fulfilled]: (state, action) => {
            if (action.payload.keyWindows) {
                keyWindowsAdapter.upsertMany(state, action.payload.keyWindows);
            }
        }
    },
});

export const {
    selectAll: selectAllKeyWindows,
    selectById: selectKeyWindowById,
    selectIds: selectKeyWindowIds,
    selectEntities: selectKeyWindowEntities,
} = keyWindowsAdapter.getSelectors((state) => state.keyWindows);

export const { setOffset: setKeyWindowOffset, removeAll: removeAllOffsets } = keyWindowsSlice.actions;

export default keyWindowsSlice.reducer
