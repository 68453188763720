import {createDraftSafeSelector, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    fetchCasesByStatus,
    fetchCurrentUser,
    fetchUsersByCompanyId,
    fetchUserById,
    fetchCasesById,
    fetchLikesForCase
} from './thunks';
import {UserEntity} from './schemas';
import {normalize} from 'normalizr';

const usersAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = usersAdapter.getInitialState({});

const usersSlice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            const result = normalize(action.payload, UserEntity).entities;

            usersAdapter.upsertMany(state, result.users);
        },
        setAvatarUpdated: (state, action) => {
            usersAdapter.upsertOne(state, {
                id: action.payload,
                avatarUpdated: Date.now()
            });
        }
    },
    extraReducers: {
        [fetchCasesByStatus.fulfilled]: (state, action) => {
            if (action.payload.users) {
                usersAdapter.upsertMany(state, action.payload.users);
            }
        },
        [fetchCurrentUser.fulfilled]: (state, action) => {
            usersAdapter.upsertMany(state, action.payload.users);
        },
        [fetchUsersByCompanyId.fulfilled]: (state, action) => {
            usersAdapter.upsertMany(state, action.payload.users);
        },
        [fetchUserById.fulfilled]: (state, action) => {
            usersAdapter.upsertMany(state, action.payload.users);
        },
        [fetchCasesById.fulfilled]: (state, action) => {
            if (action.payload.users) {
                usersAdapter.upsertMany(state, action.payload.users);
            }
        },
        [fetchLikesForCase.fulfilled]: (state, action) => {
            if (action.payload.users) {
                usersAdapter.upsertMany(state, action.payload.users);
            }
        },
    },
});

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
    selectEntities: selectUserEntities
} = usersAdapter.getSelectors((state) => state.users);

export const selectUsersByIds = (userIds) => createDraftSafeSelector([
        selectUserEntities
    ],
    (allEntities) => {
        return (userIds || []).map((id) => {
            return allEntities[id]
        }).filter(Boolean)
    }
);

export const { setCurrentUser, setAvatarUpdated } = usersSlice.actions;

export default usersSlice.reducer
