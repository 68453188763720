import {useAuth} from '../auth';
import {useAssessmentFormStorage} from './useAssessmentFormStorage';
import {useCallback, useEffect, useState} from 'react';
import flatMap from 'lodash/flatMap';
import {fetchSelfAssessment, fetchTrainingResources, postCalculateSelfAssessment} from '../assessment';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import {calculate} from '../self-assessment';
import {Assessment, Suggestion} from '@/types/assessment';
import {useStableCallback} from '@/util/hooks/useStableCallback';

export const useSelfAssessments = (): [Assessment[], () => void, boolean] => {
    const auth = useAuth();
    const [orderedSelfAssessments, setOrderedSelfAssessments] = useState<Assessment[]>([]);
    const [loading, setLoading] = useState(false);

    const reload = useStableCallback(() => {
        if (auth.isAuthenticated) {
            setLoading(true)
            fetchSelfAssessment().then((response) => {
                const ordered = orderBy(Object.values(response), 'created', 'desc');
                setOrderedSelfAssessments(ordered as (Assessment[] | null) || []);
            }).catch(console.error).finally(() => {
                setLoading(false);
            });
        }
    })

    useEffect(() => {
        reload();
    }, [auth.isAuthenticated]);

    return [orderedSelfAssessments, reload, loading];
}

export const useLastSelfAssessment = (): [Assessment | null, () => void, boolean] => {
    const [orderedSelfAssessments, reload, loading] = useSelfAssessments();
    return [orderedSelfAssessments[0] || null, reload, loading];
}

const defaultSuggestions: Suggestion[] = [];
export const useAssessmentSuggestions = (lastSelfAssessment: Assessment | null) => {
    const [suggestions, setSuggestions] = useState<Suggestion[]>(defaultSuggestions);

    useEffect(() => {
        if (lastSelfAssessment) {
            const expertiseGroups = Object.values(lastSelfAssessment.expertise_groups);
            const expertises = flatMap(expertiseGroups, g => Object.entries(g.expertises));
            const formData: {
                [key: string]: number
            } = {};

            expertises.forEach(([id, e]) => {
                formData[id] = e.score;
            });

            fetchTrainingResources(formData).then((response) => {
                setSuggestions(!Array.isArray(response) ? [] : response);
            }).catch(console.error);
        }

    }, [lastSelfAssessment]);

    if (!lastSelfAssessment) {
        return defaultSuggestions;
    }

    return suggestions;
}
