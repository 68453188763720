import React, {useEffect, useRef} from 'react';
import {useAuth} from './util/auth';
import {setCurrentUser} from './features/usersSlice';
import {removeAllOffsets} from './features/keyWindowsSlice';
import {removeAllCases} from './features/casesSlice';
import {useDispatch} from 'react-redux';
import Cookie from 'js-cookie';
import {fetchUserById, updateUserById} from './util/users';
import {setTimezone} from './features/timezoneSlice';


const CurrentUserStoreSync = () => {
    const auth = useAuth();
    const firstRun = useRef(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.user) {
            if (process.browser) {
                const timezone = Cookie.get('timezone');
                const userId = auth.user.id;

                if (!timezone && auth.user.timezone) {
                    dispatch(setTimezone(auth.user.timezone))
                } else if (timezone && auth.user.timezone !== timezone) {
                    fetchUserById(auth.user.id).then((user) => {
                        return updateUserById(userId, {
                            ...user,
                            timezone: timezone
                        });
                    }).catch((err) => {
                        console.error(err);
                    })
                }
            }
            dispatch(setCurrentUser(auth.user));
        }

        if (!firstRun.current) {
            dispatch(removeAllCases());
            dispatch(removeAllOffsets());
        }

        firstRun.current = false;
    }, [auth.user, dispatch, firstRun])

    return null;
};

export default CurrentUserStoreSync;
