import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit';
import {
    commentCase,
    commentToolbox,
    commentToolboxTool,
    fetchCasesById,
    fetchCaseChildComments,
    fetchToolboxesForCategory,
    fetchToolboxesForExpertise, fetchToolChildComments, fetchToolboxChildComments
} from './thunks';
import orderBy from 'lodash/orderBy';

const commentsAdapter = createEntityAdapter({
    sortComparer: (a, b) => {
        return a.created - b.created;
    },
})

const initialState = commentsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const commentsSlice = createSlice({
    name: 'comments',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [fetchCasesById.pending]: (state, action) => {
            state.status = 'loading'
        },
        [fetchCasesById.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [fetchCasesById.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.payload || null
        },
        [commentCase.pending]: (state, action) => {
            const { parentId } = action.meta.arg;
            
            if (parentId) {
                const parentComment = state.entities[parentId];
                
                commentsAdapter.updateOne(state, {
                    id: parentId,
                    changes: {
                        child_count: parentComment.child_count + 1
                    }
                });
            }
        },
        [commentToolbox.pending]: (state, action) => {
            const { parentId } = action.meta.arg;
            
            if (parentId) {
                const parentComment = state.entities[parentId];
                
                commentsAdapter.updateOne(state, {
                    id: parentId,
                    changes: {
                        child_count: parentComment.child_count + 1
                    }
                });
            }
        },
        [commentToolboxTool.pending]: (state, action) => {
            const { parentId } = action.meta.arg;
            
            if (parentId) {
                const parentComment = state.entities[parentId];
                
                commentsAdapter.updateOne(state, {
                    id: parentId,
                    changes: {
                        child_count: parentComment.child_count + 1
                    }
                });
            }
        },
        [commentCase.fulfilled]: (state, action) => {
            const { comment, userId, parentId } = action.meta.arg;
            const commentId = action.payload.id;

            if (commentId) {
                commentsAdapter.upsertOne(state, {
                    id: commentId,
                    created: Date.now() / 1000,
                    content: comment,
                    parent_id: parentId,
                    user: userId
                });
            }
        },
        [fetchCaseChildComments.fulfilled]: (state, action) => {
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [fetchToolboxesForCategory.fulfilled]: (state, action) => {
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [fetchToolChildComments.fulfilled]: (state, action) => {
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [fetchToolboxChildComments.fulfilled]: (state, action) => {
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [fetchToolboxesForExpertise.fulfilled]: (state, action) => {
            if (action.payload.comments) {
                commentsAdapter.upsertMany(state, action.payload.comments);
            }
        },
        [commentToolbox.fulfilled]: (state, action) => {
            const { comment, userId, parentId } = action.meta.arg;
            const commentId = action.payload.id;

            if (commentId) {
                commentsAdapter.upsertOne(state, {
                    id: commentId,
                    created: Date.now() / 1000,
                    content: comment,
                    user: userId,
                    parent_id: parentId,
                });
            }
        },
        [commentToolboxTool.fulfilled]: (state, action) => {
            const { comment, userId, parentId } = action.meta.arg;
            const commentId = action.payload.id;

            if (commentId) {
                commentsAdapter.upsertOne(state, {
                    id: commentId,
                    created: Date.now() / 1000,
                    content: comment,
                    user: userId,
                    parent_id: parentId,
                });
            }
        },
    },
});

const {
    selectAll: selectAllComments,
    selectById: selectCommentById,
    selectIds: selectCommentIds,
    selectEntities: selectCommentEntities,
} = commentsAdapter.getSelectors((state) => state.comments);

const selectCommentsIdsByParentId = createSelector(
    [selectAllComments, (state, parentId) => parentId],
    (comments, parentId) => {
        return orderBy(comments, ['created'], ['asc']).filter(comment => comment.parent_id === parentId).map(it => it.id);
    }
);

export {
    selectAllComments,
    selectCommentById,
    selectCommentIds,
    selectCommentEntities,
    selectCommentsIdsByParentId,
}


export default commentsSlice.reducer
