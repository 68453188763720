import {createAsyncThunk} from '@reduxjs/toolkit';
import * as casesAPI from '../util/cases';
import * as usersAPI from '../util/users';
import * as toolboxAPI from '../util/toolbox';
import * as assessmentAPI from '../util/assessment';
import { normalize } from "normalizr";
import {
    CaseEntity,
    CategoryEntity,
    ToolboxEntity,
    UserEntity,
    KeyWindowsEntity,
    ExpertiseEntity,
    CommentEntity
} from "./schemas";

export const fetchExpertises = createAsyncThunk('user/fetchExpertises', async () => {
    const response = await assessmentAPI.fetchExpertises();
    return normalize(response, [ExpertiseEntity]).entities;
});

export const fetchCurrentUser = createAsyncThunk('user/fetchCurrentUser', async () => {
    const response = await usersAPI.fetchCurrentUser();

    return normalize(response, UserEntity).entities;
});

export const fetchUserById = createAsyncThunk('user/fetchCurrentUser', async (id: string) => {
    const response = await usersAPI.fetchUserById(id);

    return normalize(response, UserEntity).entities;
});

export const fetchUsersByCompanyId = createAsyncThunk('user/fetchCurrentUser', async (id) => {
    const response = await usersAPI.fetchUsersByCompanyId(id);

    return normalize(response, [UserEntity]).entities;
});

export const fetchCasesByStatus = createAsyncThunk('cases/fetchCases', async ({
    status,
    limit = null,
    offset = null,
    category = null,
    userId,
    orderBy,
    sortOrder,
    onlyFuture,
    onlyPast,
    onlyMine,
    ctx
}: {
    status: string,
    limit?: number,
    offset?: number,
    category?: string,
    userId?: string,
    orderBy?: string,
    sortOrder?: string,
    onlyFuture?: boolean,
    onlyPast?: boolean,
    onlyMine?: boolean,
    ctx?: any
}) => {
    const response = await casesAPI.fetchCasesByStatus({status, limit, offset, category, orderBy, sortOrder, onlyFuture}, ctx);

    const hubcases = (response.hubcases || []).map((it, index) => {
        return {
            ...it,
            order: index + offset
        }
    });

    return normalize({
        ...response,
        hubcases: hubcases,
        keyWindows: {
            id: [status, category, userId, orderBy, sortOrder, onlyMine].filter(Boolean).join('_'),
            name: status,
            status: status,
            limit: limit,
            offset: offset,
            category: category,
            found: response.found
        }
    }, {
        hubcases: [CaseEntity],
        categories: [CategoryEntity],
        keyWindows: KeyWindowsEntity
    }).entities;
});

export const fetchCasesById = createAsyncThunk('cases/fetchCaseById', async ({caseId, ctx} : {
    caseId: string,
    ctx?: any
}) => {
    const response = await casesAPI.fetchCaseById(caseId, ctx);

    //API returns 200 for cases that not exist
    if (!response || !Object.keys(response).length) {
        return {cases: {}};
    }

    return normalize(response, CaseEntity).entities;
});

export const createNewCase = createAsyncThunk(
    'cases/createNewCase',
    async ({title, content, key_question, is_anonymous}: {
        title: string,
        content: string,
        key_question: string,
        is_anonymous: boolean
    }) => {
        const response = await casesAPI.createCase({title, content, key_question, is_anonymous});
        const caseId = response.id;
        const result = await casesAPI.fetchCaseById(caseId);

        return normalize(result, CaseEntity).entities;
    }
);

export const fetchLikesForCase = createAsyncThunk('cases/fetchLikesForCase', async ({caseId}) => {
    const response = await casesAPI.fetchLikesForCase(caseId);

    return normalize(response.like, [UserEntity]).entities;
});

export const likeCase = createAsyncThunk('cases/likeCase', async ({caseId}: {caseId: string}) => {
    const response = await casesAPI.likeCase(caseId);
    return response
});

export const addToolToBookmarks = createAsyncThunk('toolboxes/bookmark', async ({toolId}: {toolId: string}) => {
    const response = await usersAPI.postCreateBookmark('tool', toolId, 'bookmark');
    return response
});

export const addToolToFavorites = createAsyncThunk('toolboxes/favorites', async ({toolId}: {toolId: string}) => {
    const response = await usersAPI.postCreateBookmark('tool', toolId, 'favorite');
    return response
});
export const addCaseToBookmarks = createAsyncThunk('cases/bookmark', async ({caseId}: {caseId: string}) => {
    const response = await usersAPI.postCreateBookmark('hubcase', caseId, 'bookmark');
    return response
});

export const addCaseToFavorites = createAsyncThunk('cases/favorites', async ({caseId}: {caseId: string}) => {
    const response = await usersAPI.postCreateBookmark('hubcase', caseId, 'favorite');
    return response
});

export const deleteToolFromBookmarks = createAsyncThunk('toolboxes/deletebookmark', async ({bookmarkId}: {toolId: string, bookmarkId: string}) => {
    const response = await usersAPI.postDeleteBookmark(bookmarkId);
    return response
});

export const deleteToolFromFavorites = createAsyncThunk('toolboxes/deletefavorite', async ({bookmarkId}: {toolId: string, bookmarkId: string}) => {
    const response = await usersAPI.postDeleteBookmark(bookmarkId);
    return response
});

export const deleteCaseFromBookmarks = createAsyncThunk('cases/deletebookmark', async ({bookmarkId}: {caseId: string, bookmarkId: string}) => {
    const response = await usersAPI.postDeleteBookmark(bookmarkId);
    return response
});

export const deleteCaseFromFavorites = createAsyncThunk('cases/deletefavorite', async ({bookmarkId}: {caseId: string, bookmarkId: string}) => {
    const response = await usersAPI.postDeleteBookmark(bookmarkId);
    return response
});

export const commentCase = createAsyncThunk('cases/commentCase', async ({caseId, parentId, comment}: {
    caseId: string,
    comment: string,
    userId: string
    parentId?: string
}) => {
    const response = await casesAPI.commentCase(caseId, parentId, comment);
    return response;
});

export const fetchCaseChildComments = createAsyncThunk('cases/fetchCaseChildComments', async ({caseId, commentId}: {caseId: string, commentId: string}) => {
    const response = await casesAPI.fetchCaseChildComments(caseId, commentId);

    // todo ask for parent_id in response
    (response || []).forEach((it) => {
        it.parent_id = commentId;
    })

    return normalize(response, [CommentEntity]).entities;
});

export const fetchToolboxChildComments = createAsyncThunk('cases/fetchToolboxChildComments', async ({toolboxId, commentId}: {toolboxId: string, commentId: string}) => {
    const response = await toolboxAPI.fetchToolboxChildComments(toolboxId, commentId);

    // todo ask for parent_id in response
    (response || []).forEach((it) => {
        it.parent_id = commentId;
    })

    return normalize(response, [CommentEntity]).entities;
});

export const fetchToolChildComments = createAsyncThunk('cases/fetchToolChildComments', async ({toolId, commentId}: {toolId: string, commentId: string}) => {
    const response = await toolboxAPI.fetchToolChildComments(toolId, commentId);

    // todo ask for parent_id in response
    (response || []).forEach((it) => {
        it.parent_id = commentId;
    })

    return normalize(response, [CommentEntity]).entities;
});

export const commentToolbox = createAsyncThunk('cases/commentToolbox', async ({toolboxId, comment, parentId}: {
    toolboxId: string,
    comment: string,
    userId: string,
    parentId?: string
}) => {
    const response = await toolboxAPI.commentToolbox(toolboxId, comment, parentId);
    return response;
});

export const commentToolboxTool = createAsyncThunk('cases/commentToolboxTool', async ({toolId, comment, parentId}: {
    toolId: string,
    comment: string,
    userId: string,
    parentId?: string
}) => {
    const response = await toolboxAPI.commentToolboxTool(toolId, comment, parentId);
    return response;
});

export const toggleCaseRegistration = createAsyncThunk('cases/register', async ({caseId}: {caseId: string, userId: string}) => {
    const response = await casesAPI.toggleCaseRegistration(caseId);
    return response;
});

export const fetchToolboxesForCategory = createAsyncThunk('toolboxes/fetchForCategory', async ({category, ctx}) => {
    const response = await toolboxAPI.fetchToolboxesForCategory(category, ctx);

    //TODO ask to add category id to service response
    const result = response.map(it => ({
        ...it,
        categories: [category]
    }));

    return normalize(result, [ToolboxEntity]).entities;
});

export const fetchToolboxesForExpertise = createAsyncThunk('toolboxes/fetchForCategory', async ({expertise, ctx}: {
    expertise: string,
    ctx?: any
}) => {
    let response = await toolboxAPI.fetchToolboxesForExpertise(expertise, ctx);

    //fallback to fetching by category so old links would still work
    if (!response.length) {
        response = await toolboxAPI.fetchToolboxesForCategory(expertise, ctx);
    }

    // //TODO ask to add category id to service response
    const result = response.map(it => ({
        ...it,
        categories: [expertise]
    }));

    return normalize(result, [ToolboxEntity]).entities;
});

export const fetchToolboxCategories = createAsyncThunk('toolboxes/fetchToolboxCategories', async () => {
    const response = await toolboxAPI.fetchToolboxCategories();

    //TODO ask for endpoint to get category by id
    function walkCategories(list, cb) {
        (list || []).forEach((it) => {
            cb(it);
            if (it.children) {
                walkCategories(it.children, cb);
            }
        })
    }

    const allCategories = [];
    walkCategories(response, (it) => {
        allCategories.push(it);
    });

    return normalize(allCategories, [CategoryEntity]).entities;
});
