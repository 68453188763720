import {
    createEntityAdapter,
    createSlice,
    createDraftSafeSelector,
    EntityAdapter,
    PayloadAction
} from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import {
    fetchCasesByStatus,
    fetchCasesById,
    createNewCase,
    likeCase,
    commentCase,
    toggleCaseRegistration,
    fetchLikesForCase, addCaseToBookmarks, addCaseToFavorites, deleteCaseFromBookmarks, deleteCaseFromFavorites
} from './thunks';
import {CaseStatuses} from '@/constants';

const casesAdapter: EntityAdapter<{
    excerpt: string;
    expertises: {
        id: string;
        title: string;
        created: number;
        modified: number;
        has_public_hubcase: boolean;
        has_public_tool: boolean;
        expertise_group_id: string;
    }[];
    user?: string;
    duration: number;
    scheduled_to: number;
    zoom_us_link?: string;
    participants: {
        id: string;
        created: number;
        last_name: string;
        first_name: string;
    }[];
    register_max: number;
    public: boolean;
    state: CaseStatuses;
    created: number,
    id: string,
    title: string,
    content: string,
    key_question: string,
    is_anonymous: boolean,
    status: CaseStatuses,
    category: string,
    user_participant: boolean,
    user_trainingplan: boolean,
    user_like: boolean,
    like_count: number,
    comment_count: number,
    register_count: number,
    likes: string[],
    comments: string[],
    user_bookmark: boolean,
    user_favorite: boolean,
}> = createEntityAdapter({
    sortComparer: (a, b) => b.created - a.created
});

const initialState = casesAdapter.getInitialState({
    status: 'idle',
    error: null,
    found: 0
});

const casesSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        removeAll: (state, action) => {
            casesAdapter.removeAll(state);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(likeCase.pending, (state, action) => {});
        builder.addCase(likeCase.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const existingCase = state.entities[caseId];

            const added = action.payload.status === 'ADDED';

            casesAdapter.updateOne(state, {
                id: caseId,
                changes: {
                    like_count: added ? existingCase.like_count + 1 : existingCase.like_count - 1,
                    user_like: added,
                }
            });
        })
        builder.addCase(deleteCaseFromBookmarks.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const existingCase = state.entities[caseId];

            if (existingCase && action.payload.status === 'SUCCESS') {
                casesAdapter.updateOne(state, {
                    id: caseId,
                    changes: {
                        user_bookmark: false,
                    }
                });
            }
        })
        builder.addCase(deleteCaseFromFavorites.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const existingCase = state.entities[caseId];

            if (existingCase && action.payload.status === 'SUCCESS') {
                casesAdapter.updateOne(state, {
                    id: caseId,
                    changes: {
                        user_favorite: false,
                    }
                });
            }
        })
        builder.addCase(addCaseToBookmarks.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const existingCase = state.entities[caseId];

            if (existingCase && action.payload.status === 'SUCCESS') {
                casesAdapter.updateOne(state, {
                    id: caseId,
                    changes: {
                        user_bookmark: true,
                    }
                });
            }
        })
        builder.addCase(addCaseToFavorites.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const existingCase = state.entities[caseId];

            if (existingCase && action.payload.status === 'SUCCESS') {
                casesAdapter.updateOne(state, {
                    id: caseId,
                    changes: {
                        user_favorite: true,
                    }
                });
            }
        })
        builder.addCase(fetchLikesForCase.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const users = action.payload.users;
            const existingCase = state.entities[caseId];
            if (users && existingCase) {
                existingCase.likes = Object.keys(users);
            }
        });
        builder.addCase(toggleCaseRegistration.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;

            const added = action.payload.status === 'ADDED';
            const existingCase = state.entities[caseId];

            casesAdapter.updateOne(state, {
                id: caseId,
                changes: {
                    user_participant: added,
                    register_count: added ? (existingCase.register_count || 0) + 1 : (existingCase.register_count || 1) - 1
                }
            });
        });

        builder.addCase(fetchCasesByStatus.pending, (state, action) => {
            state.status = 'loading'
        });

        builder.addCase(fetchCasesByStatus.fulfilled, (state, action) => {
            state.status = 'succeeded'
            if (action.payload.cases) {
                casesAdapter.upsertMany(state, action.payload.cases)
            }
        });
        builder.addCase(fetchCasesByStatus.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.payload || null;
        });

        builder.addCase(fetchCasesById.fulfilled, (state, action) => {
            casesAdapter.upsertMany(state, action.payload.cases);
        });

        builder.addCase(commentCase.fulfilled, (state, action) => {
            const { caseId } = (action.meta as any).arg;
            const commentId = action.payload.id;
            const existingCase = state.entities[caseId];
            const existingComments = existingCase.comments || [];

            if (commentId) {
                existingComments.unshift(commentId);
                casesAdapter.updateOne(state, {
                    id: caseId,
                    changes: {
                        comments: existingComments,
                        comment_count: (existingCase.comment_count || 0) + 1
                    }
                })
            }
        });

        builder.addCase(createNewCase.fulfilled, (state, action) => {
            if (action.payload.cases) {
                casesAdapter.upsertMany(state, action.payload.cases)
            }
        });
    },
});

export const {
    selectAll: selectAllCases,
    selectById: selectCaseById,
    selectIds: selectCaseIds,
    selectEntities: selectCaseEntities,
} = casesAdapter.getSelectors((state) => state.cases);

export const selectCasesByIds = (caseIds) => createDraftSafeSelector([
        selectCaseEntities
    ],
    (allEntities) => {
        return caseIds.map((id) => {
            return allEntities[id]
        }).filter(Boolean)
    }
);

export const selectCasesCategoriesByIds = (caseIds) => createDraftSafeSelector([
        selectCasesByIds(caseIds)
    ],

    (cases) => {
        return flow(
            (cases) => {
                return cases.map(it => {
                    if (Array.isArray(it.categories)) {
                        return it.categories;
                    }

                    return it.category;
                });
            },
            flatten,
            (categories) => categories.filter(Boolean),
            uniq
        )(cases);
    }
);

type MakeGetCaseByStatusParams = {
    selectedStatus: CaseStatuses[],
    sortKey?: string,
    sortOrder?: 'asc' | 'desc',
    userParticipantFilter?: boolean
}
export const makeGetCaseByStatus = ({
    selectedStatus,
    sortKey,
    sortOrder = 'asc',
    userParticipantFilter
}: MakeGetCaseByStatusParams) => createDraftSafeSelector([
    selectAllCases
], (allCases) => {
    const filtered = allCases.filter(({state, user_participant}) => {
        if (userParticipantFilter !== undefined && userParticipantFilter !== user_participant) {
            return false;
        }

        if (Array.isArray(selectedStatus)) {
            return selectedStatus.includes(state)
        }
        return state === selectedStatus;
    });

    if (sortKey) {
        return orderBy(filtered, ['order', sortKey], [sortOrder, sortOrder])
    }

    return filtered;
});

export const makeGetCaseIdsByStatus = (selectedStatus) => createDraftSafeSelector([
    makeGetCaseByStatus({selectedStatus: selectedStatus}),
], (allCases) => {
    return allCases.map(it => it.id)
});

export const makeGetClosedCases = (
    sortOrder,
) => {
    return createDraftSafeSelector([
        makeGetCaseByStatus({
            selectedStatus: [CaseStatuses.CLOSED],
            sortKey: 'created',
            sortOrder
        }),
    ], (allCases) => {
        return allCases;
    });
};

export const { removeAll: removeAllCases } = casesSlice.actions;

export default casesSlice.reducer
