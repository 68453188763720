import React from 'react';
import {useAuth, AuthStates} from '../util/auth';
import cn from 'clsx';
import {MobileNav} from './MobileNav';
import {UserNav} from '@/components/UserNav';
import {HeaderNav} from '@/components/HeaderNav';
import {Icons} from '@/components/icons';
import Link from 'next/link';
import {Button} from '@/components/ui/button';
import {User} from 'lucide-react';

export type NavMenuItem = {
    id: string
    name: string | React.ReactNode
    href?: string
    description?: string
    footerContent?: React.ReactNode
    children?: NavMenuItem[]
    disabled?: boolean
    hasPublicContent?: boolean
    authStates?: AuthStates;
}

type NavbarProps = {
    menuItems: NavMenuItem[];
}

export const Navbar = ({
    menuItems
}: NavbarProps) => {
    const auth = useAuth();
    const filteredMenuItems = menuItems.filter((it) => {
        return !it.authStates || it.authStates.includes(auth.state);
    });

    return (
        <div className="top-[0] sticky bg-backgroundBrand/90 backdrop-blur z-10 border-b-[1px] border-b-gray-200">
            <div className="container">
                <nav className={cn('py-4 flex items-center min-h-[88px]', {
                    // 'shadow-xl': scrollPosition >= 10,
                })}>
                    <div className="mr-auto">
                        <Link href="/">
                            <a className="mr-6 flex items-center space-x-2">
                                <Icons.logo
                                    className="w-auto h-14"/>
                            </a>
                        </Link>
                    </div>
                    <div className="flex justify-center gap-2">
                        <HeaderNav menuItems={filteredMenuItems}/>
                        {auth.state === AuthStates.AUTHENTICATED ? (
                            <UserNav/>
                        ) : (
                            <div className="hidden lg:flex gap-2">
                                <Link passHref href="/auth/signin">
                                    <Button size="lg" variant="outline" asChild className="shrink-0">
                                        <a><User className="w-4 h-4"/> Login</a>
                                    </Button>
                                </Link>
                                <Link passHref href="/auth/signup">
                                    <Button size="lg" asChild className="shrink-0">
                                        <a>Register for free</a>
                                    </Button>
                                </Link>
                            </div>
                        )}
                        <MobileNav menuItems={filteredMenuItems}/>
                    </div>
                </nav>
            </div>
        </div>
    )
};

export default Navbar;
