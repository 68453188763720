import React, {useState} from 'react';
import {AuthStates, useAuth} from '../util/auth';
import ConditionalWrapper from './ConditionalWrapper';
import ActiveLink from './ActiveLink';
import {ChevronRight, ChevronLeft, LogOut, LogIn}  from 'lucide-react';
import {Button} from '@/components/ui/button';
import {cn} from '@/lib/utils';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {NavMenuItem} from '@/components/Navbar';

const useNavigationState = (menuItems: NavMenuItem[]) => {
    const [history, setHistory] = useState<number[]>([]);

    const currentLevelMenuItems = history.reduce((items, lvl) => {
        return items[lvl]?.children || items;
    }, menuItems);

    return {
        currentLevel: history.length,
        currentLevelMenuItems: currentLevelMenuItems,
        openMenuItemByIndex: (index: number) => {
            setHistory([
                ...history,
                index
            ]);
        },
        goBack: () => {
            setHistory(history.slice(0, -1));
        }
    };
}

const OffscreenMenu = ({menuItems, maxLevel = 1}: {
    menuItems: NavMenuItem[],
    maxLevel?: number
}) => {
    const auth = useAuth();
    const router = useRouter();

    const {currentLevelMenuItems, currentLevel, openMenuItemByIndex, goBack} = useNavigationState(menuItems.filter((it) => {
        return !it.authStates || it.authStates.includes(auth.state);
    }));

    return (
        <MenuList
            level={currentLevel}
            maxLevel={maxLevel}
            items={currentLevelMenuItems}
            openMenuItemByIndex={openMenuItemByIndex}
            startLinks={
                <>
                    {currentLevel > 0 && (<li className="flex items-end justify-end w-full">
                        <Button size="icon" variant="link" onClick={goBack}>
                            <ChevronLeft/>
                        </Button>
                    </li>)}
                    {
                        (currentLevel === 0 && auth.isAuthenticated) ? (
                            (
                                <li>
                                    <ActiveLink href={'/user/dashboard'} activeClassName="font-md" passHref>
                                        <Button asChild variant="link">
                                            <a>My home</a>
                                        </Button>
                                    </ActiveLink>
                                </li>
                            )
                        ) : null
                    }
                </>
            } endLinks={
            <li className="mt-6 flex justify-between">
                {auth.state !== AuthStates.AUTHENTICATED ? (
                    <Link href="/auth/signin" passHref>
                        <Button
                            variant="ghost"
                            asChild
                        >
                            <a><LogIn /> Log in</a>
                        </Button>
                    </Link>
                ) : (
                    <Button
                        variant="ghost"
                        onClick={() => {
                            auth.signout()
                            router.push('/auth/signin');
                        }}
                    >
                        <LogOut /> Log out
                    </Button>
                )}
            </li>
        }
        />
    )
};

const MenuList = ({items, level = 0, maxLevel, startLinks, endLinks, openMenuItemByIndex}: {
    items: NavMenuItem[],
    level?: number,
    maxLevel?: number,
    startLinks?: React.ReactNode,
    endLinks?: React.ReactNode,
    openMenuItemByIndex: (index: number) => void
    renderChildrenOnSameLevel?: boolean
}) => {
    const renderChildrenOnSameLevel= level >= (maxLevel || 0);

    return (
        <ul>
            {startLinks}
            {items.map((it, index) => {
                const hasChildren = it?.children?.length;

                return <li className="flex flex-col justify-between pr-4" key={index} onClick={(e) => {
                    if (it?.children && !renderChildrenOnSameLevel && !it.disabled) {
                        e.preventDefault();
                        openMenuItemByIndex(index)
                    }
                }}>
                    <ConditionalWrapper
                        condition={!!(it.href && !hasChildren && !it.disabled)}
                        wrapper={(children) => {
                            if (!it.href) {
                                return children;
                            }
                            return (
                                <ActiveLink href={it.href} passHref activeClassName="">
                                    <a>{children}</a>
                                </ActiveLink>
                            )
                        }}
                    >
                        <div className="flex justify-between">
                            <Button variant="link" className={cn({
                                'text-md font-semibold mt-4': (level === 1) && hasChildren,
                            })}>
                                {it.name}
                            </Button>

                            {hasChildren && !renderChildrenOnSameLevel ? (
                                <ChevronRight/>
                            ) : null}
                        </div>
                        {renderChildrenOnSameLevel && hasChildren ? (<MenuList
                            items={it.children || []}
                            level={level+1}
                            maxLevel={maxLevel}
                            openMenuItemByIndex={openMenuItemByIndex}
                            renderChildrenOnSameLevel={renderChildrenOnSameLevel}
                        />) : null}
                    </ConditionalWrapper>
                </li>
            })}
            {endLinks}
        </ul>
    )
}

export default OffscreenMenu;
