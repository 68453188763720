import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar';
import React, {useEffect} from 'react';
import {useAuth} from '@/util/auth';
import {cn} from '@/lib/utils';
import {useDispatch, useSelector} from 'react-redux';
import {selectUserById} from '@/features/usersSlice';
import {TodoState} from '@/types/todo';
import {fetchUserById} from '@/features/thunks';
import {User} from 'lucide-react';
import Link from 'next/link';

type User = {
    first_name: string;
    last_name: string;
    id: string;
    avatarUpdated?: number;
}

type UserAvatarProps = {
    userId?: string;
    className?: string;
    clickable?: boolean;
}

const activeUserRequest = new Map<string, boolean>();

const useUser = (userId?: string) => {
    const userFromState: User = useSelector((state: TodoState) => userId ? selectUserById(state, userId) : null);
    const userFromStateId = userFromState ? userFromState.id : null;
    const hasUserRef = React.useRef(false);
    const dispatch = useDispatch();
    hasUserRef.current = userFromStateId === userId;


    // TODO think about react query instead
    useEffect(() => {
        if (userId && !hasUserRef.current && !activeUserRequest.get(userId)) {
            activeUserRequest.set(userId, true);
            dispatch(fetchUserById(userId));
        }
    }, [userId]);

    return userFromState;
}

export const useUserAvatar = (userId?: string) => {
    const auth = useAuth();
    const user: User | null = useUser(userId);
    const avatarUpdated = user?.avatarUpdated;
    const avatarPath = userId ? `/api/ch/user/${userId}/avatar?a${auth.isAuthenticated ? 1 : 0}` : '';
    const userInitials = userId ? `${user?.first_name?.[0] || ''}${user?.last_name?.[0] || ''}` : '';

    if(!user) {
        return {
            avatarPath: '',
            userInitials: ''
        }
    }

    return {
        avatarPath: avatarPath + `&${avatarUpdated || ''}`,
        userInitials
    }
}


export const UserAvatar = ({ userId, className, clickable = true }: UserAvatarProps) => {
    const { avatarPath, userInitials } = useUserAvatar(userId);

    if (userId && clickable) {
        return (
            <Link href={`/user/${userId}`} passHref>
                <Avatar className={cn('h-12 w-12', className)} asChild>
                    <a>
                        <AvatarImage src={avatarPath} />
                        <AvatarFallback>
                            <>
                                {!userInitials ? (
                                    <User className="w-[50%] h-[50%] text-muted-foreground/50" />
                                ) : userInitials}
                            </>
                        </AvatarFallback>
                    </a>
                </Avatar>
            </Link>
        )
    }

    return (
        <Avatar className={cn('h-12 w-12', className)}>
            <AvatarImage src={avatarPath} />
            <AvatarFallback>
                <>
                    {!userInitials ? (
                        <User className="w-[50%] h-[50%] text-muted-foreground/50" />
                    ) : userInitials}
                </>
            </AvatarFallback>
        </Avatar>
    )
}
