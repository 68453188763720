import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    fetchToolboxesForCategory, fetchToolboxesForExpertise
} from './thunks';

const videosAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = videosAdapter.getInitialState({});

const videosSlice = createSlice({
    name: 'videos',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [fetchToolboxesForCategory.fulfilled]: (state, action) => {
            if (action.payload.videos) {
                videosAdapter.upsertMany(state, action.payload.videos)
            }
        },
        [fetchToolboxesForExpertise.fulfilled]: (state, action) => {
            if (action.payload.videos) {
                videosAdapter.upsertMany(state, action.payload.videos)
            }
        }
    },
});

export const {
    selectAll: selectAllVideos,
    selectById: selectVideoById,
    selectIds: selectVideoIds,
    selectEntities: selectVideoEntities,
} = videosAdapter.getSelectors((state) => state.videos);

export default videosSlice.reducer
