import {useEffect} from 'react';
import {useRouter} from 'next/router';
import {pixelID} from '../config';

export const FacebookPixel = () => {
    const router = useRouter();

    useEffect(() => {
        if (pixelID) {
            import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init(pixelID)
                ReactPixel.pageView()

                router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView()
                });
            })
        }
    }, [router]);

    return null;
}
