import {apiRequest} from './util';
import {activateTrialSubscription} from './pricing';
import {User, UserConsentResponse} from '@/types/user';

export const fetchCurrentUser = async (ctx: any, trialRequested = false): Promise<User> => {
    let user = (await apiRequest({path: '/ch/user/me', ctx}) as User);

    if (!trialRequested && user?.trial_available) {
        try {
            await activateTrialSubscription(ctx);
            return fetchCurrentUser(ctx, true);
        } catch (err) {
            console.error(err);
        }
    }

    return user;
}

export const fetchUserById = async (id: string):Promise<User> => {
    const user = (await apiRequest({path: `/ch/user/${id}`}) as User);

    return user;
}

export const fetchCurrentUserComments = async () => {
    const comments = await apiRequest({path: `/ch/user/me/comments`});

    return comments;
}

export const fetchCurrentUserBookmarks = async () => {
    const bookmarks = await apiRequest({path: `/ch/user/me/bookmarks`});

    return bookmarks;
}

export const postCreateBookmark = async (resourceType: 'hubcase' | 'tool', resourceId: string, type: 'favorite' | 'bookmark') => {
    const form = {
        resource_type: resourceType,
        resource_id: resourceId,
        type
    }
    return apiRequest({path: `/ch/user/me/bookmark`, method: 'POST', data: form});
}

export const postDeleteBookmark = async (bookmarkId: string) => {
    const form = {
        id: bookmarkId,
    }
    return apiRequest({path: `/ch/user/me/deletebookmark`, data: form, method: 'POST'});
}

export const postFeedbackRequest = async (assesmentId, data: {[key: string]: {value: string}[]}) => {
    const form: {
        assessment_result_id: string,
        requestfeedback_form: {
            [key: string]: string
        }[],
    } = {
        assessment_result_id: assesmentId,
        requestfeedback_form: []
    };

    Object.keys(data).forEach((key) => {
        const item: {value: string}[] = data[key];

        item.forEach((item, index) => {
            form.requestfeedback_form[index] = form.requestfeedback_form[index] || {};
            form.requestfeedback_form[index][key] = item.value;
        })
    })

    return apiRequest({path: `/ch/user/me/requestfeedback`, method: 'POST', data: form});
}


export const fetchSelfReflections = async () => {
    const reflections = await apiRequest({path: `/ch/user/me/reflections`});

    return reflections;
}

export const fetchSkillCoverage = async () => {
    const skillCoverage = await apiRequest({path: `/ch/user/me/skillcoverage`});

    return skillCoverage;
}

export const postSelfReflection = async ({
    score,
    learnings,
    challenge,
    highlights,
    forward
}: {
    score: number
    learnings: string
    challenge: string
    highlights: string
    forward: string
}) => {
    return apiRequest({path: `/ch/user/me/reflection`, method: 'POST', data: {
        score,
        learnings,
        challenge,
        highlights,
        forward
    }});
}

export const updateUserById = async (id: string, data:User) => {
    const user = await apiRequest({path: `/ch/user/${id}`, method: 'POST', data: data});

    return user;
}

export const fetchUsersByCompanyId = async (id: string) => {
    const users = await apiRequest({path: `/ch/users?company_id=${id}`});

    return users;
}

export const uploadUserAvatar = async (id: string, file: Blob) => {
    return apiRequest({
        path: `/ch/user/${id}/avatar`,
        method: 'PUT',
        data: file,
        rawData: true,
        responseFormat: 'blob'
    });
}

export const updateUserPassword = async ({id, old_password, password, password_repeat}: {
    id: number,
    old_password: string,
    password: string,
    password_repeat: string
}) => {
    return apiRequest({
        path: `/ch/user/${id}/password`,
        method: 'POST',
        data: {
            old_password,
            new_password1: password,
            new_password2: password_repeat
        }
    });
}

export const verifyEmail = async ({email, hash}: {
    email: string,
    hash: string
}) => {
    return await apiRequest({path: `/ch/user/me/verifyemail`, method: 'POST', data: {
        email,
        hash
    }});
}

export const fetchUserConsents = async () => {
    const result = await apiRequest({path: `/ch/user/me/consents`});
    return result as UserConsentResponse;
}

export const postUserConsent = async ({consent = 'COMMENT_REPLY'}: {
    consent: string
}) => {
    const result = await apiRequest({path: `/ch/user/me/consent`, method: 'POST', data: {
        consent,
    }});

    return result;
}

export const createNamePlaceholder = (len: number) => {
    const characters = 'Abcdefghijklmnopqrstuvwxyz';

    return characters.slice(0, len)
}
