import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import { cn } from '@/lib/utils';

interface ActiveLinkProps extends LinkProps {
    children: ReactElement;  // specify that children must be a ReactElement
    activeClassName: string;
    href: string;
}

const ActiveLink: React.FC<ActiveLinkProps> = ({ children, activeClassName, ...props }) => {
    const { asPath } = useRouter();

    const childClassName = children.props.className || '';

    // pages/index.js will be matched via props.href
    // pages/about.js will be matched via props.href
    // pages/[slug].js will be matched via props.as
    const className =
        asPath === props.href || asPath === props.as
            ? cn(childClassName, activeClassName)
            : childClassName;

    return (
        <Link {...props}>
            {React.cloneElement(children, {
                className: className,
            })}
        </Link>
    );
}

export default ActiveLink;
