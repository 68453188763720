"use client"

import * as React from "react"
import Link from "next/link"

import { cn } from "@/lib/utils"
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import {Separator} from '@/components/ui/separator';
import {Badge} from '@/components/ui/badge';
import {NavMenuItem} from '@/components/Navbar';


type HeaderNavProps = {
    menuItems: NavMenuItem[]
}

export function HeaderNav({
    menuItems
}: HeaderNavProps) {
    return (
        <NavigationMenu className="hidden lg:flex">
            <NavigationMenuList>
                {
                    menuItems.map(it => {
                        if (!it.children || it.children.length === 0) {
                            return (
                                <NavigationMenuItem key={it.id}>
                                    <Link href={it.href || '/'} legacyBehavior passHref>
                                        <NavigationMenuLink className={cn(navigationMenuTriggerStyle(), 'font-semibold')}>
                                            {it.name}
                                        </NavigationMenuLink>
                                    </Link>
                                </NavigationMenuItem>
                            );
                        }
                        return (
                            <NavigationMenuItem key={it.id}>
                                <NavigationMenuTrigger className="font-semibold">{it.name}</NavigationMenuTrigger>
                                <NavigationMenuContent>
                                    <div className="flex">
                                        {it.children.map((child) => {
                                            const getChildrenRecursive = (currentItem: NavMenuItem, list: NavMenuItem[]) => {
                                                if (!currentItem.children || currentItem.children.length === 0) {
                                                    return list;
                                                }

                                                currentItem.children.forEach((child) => {
                                                    list.push(child);
                                                    getChildrenRecursive(child, list);
                                                });

                                                return list;
                                            }

                                            const allNestedChildren = getChildrenRecursive(child, []);

                                            return (
                                                <React.Fragment key={child.id}>
                                                    <ul className="flex flex-col sm:w-[230px] gap-2 p-2">
                                                        <ListItem
                                                            href={child.href}
                                                            className={cn("p-2", {
                                                                'pointer-events-none': child.disabled
                                                            })}
                                                        >
                                                            <ListItemTitle className="font-bold">
                                                                {child.name}
                                                            </ListItemTitle>
                                                            {!!child.description && (
                                                                <ListItemDescription>
                                                                    {child.description}
                                                                </ListItemDescription>
                                                            )}

                                                            {!!child.footerContent && (
                                                                <ListItemFooter>
                                                                    {child.footerContent}
                                                                </ListItemFooter>
                                                            )}
                                                        </ListItem>
                                                        {allNestedChildren.map((nestedChild) => {
                                                            return (
                                                                <ListItem
                                                                    key={nestedChild.id}
                                                                    href={nestedChild.href}
                                                                    className="p-2"
                                                                >
                                                                    <ListItemTitle>
                                                                        {nestedChild.name}
                                                                    </ListItemTitle>
                                                                    {!!nestedChild.description && (
                                                                        <ListItemDescription>
                                                                            {nestedChild.description}
                                                                        </ListItemDescription>
                                                                    )}

                                                                    {!!nestedChild.footerContent && (
                                                                        <ListItemFooter>
                                                                            {nestedChild.footerContent}
                                                                        </ListItemFooter>
                                                                    )}
                                                                </ListItem>
                                                            )
                                                        })}
                                                    </ul>
                                                    <Separator orientation="vertical" />
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </NavigationMenuContent>
                            </NavigationMenuItem>
                        );
                    })
                }
            </NavigationMenuList>
        </NavigationMenu>
    )
}

const ListItemTitle = React.forwardRef<
    React.ElementRef<"p">,
    React.ComponentPropsWithoutRef<"p">
>(({
    className,
    children,
    ...props }, ref) => {
    return (
        <p className={cn("text-sm font-medium leading-none", className)} {...props}>
            {children}
        </p>
    )
})

const ListItemDescription = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({
    className,
    children,
    ...props }, ref) => {
    return (
        <div className={cn("line-clamp-2 text-sm leading-snug text-muted-foreground", className)} {...props}>
            {children}
        </div>
    )
})

const ListItemFooter = React.forwardRef<
    React.ElementRef<"div">,
    React.ComponentPropsWithoutRef<"div">
>(({
    className,
    children,
    ...props }, ref) => {
    return (
        <div className={cn("mt-2 text-xs text-accent-foreground", className)} {...props}>
            {children}
        </div>
    )
})

const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
>(({
    className,
    children,
    ...props }, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        "block select-none space-y-1 rounded-2xl p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}
                    {...props}
                >
                    {children}
                </a>
            </NavigationMenuLink>
        </li>
    )
})

const buildToolboxLinks = (items: NavMenuItem[], hideHasPublicContentLabels: boolean, level = 0): NavMenuItem[] => {
    if (!items) {
        return [];
    }

    return items.map((it) => {
        const isClickable = level > 0;

        return ({
            id: it.id,
            name: isClickable && !hideHasPublicContentLabels && it.hasPublicContent ? (<>{it.name} <Badge>free</Badge></>) : it.name,
            href: `/toolbox/${it.id}`,
            description: it.description,
            disabled: !isClickable,
            children: it?.children?.length ? buildToolboxLinks(it.children, hideHasPublicContentLabels, level + 1) : undefined
        })
    })
}


ListItem.displayName = "ListItem"
