import { format as formatter, utcToZonedTime } from 'date-fns-tz';
import {formatDistanceToNow} from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';

export const formatName = (user?: {
    first_name: string,
    last_name: string,
}, format: FormatName = FormatName.Short) => {
    if (!user) {
        return '';
    }

    if (format === FormatName.Long) {
        return [user.first_name, user.last_name].join(' ');
    }

    if (format === FormatName.Medium) {
        return [user.first_name, user.last_name.substring(0, 1)].join(' ');
    }

    return user.first_name;
}

export enum FormatName {
    Short = 'short',
    Medium = 'medium',
    Long = 'long',
}
export const formatDateTz = ({ts, format = FormatName.Short, tz}: {
    ts: number,
    format?: FormatName,
    tz: string,
}) => {
    const formats = {
        [FormatName.Short]: 'dd.MM',
        [FormatName.Medium]: 'dd.MM.yyyy',
        [FormatName.Long]: 'dd.MM.yyyy HH:mm \'GMT\' XXX',
    }

    if (!formats[format]) {
        throw new Error(`Unknown format: ${format}`)
    }

    const localDate = utcToZonedTime(ts * 1000, tz)

    return formatter(localDate, formats[format], {timeZone: tz})
}

export const formatDateDefault = ({ts, format = FormatName.Short}: {
    ts: number
    format: FormatName
}) => {
    const formats: {[key: string]: string} = {
        [FormatName.Short]: 'dd.MM',
        [FormatName.Medium]: 'dd.MM.yyyy',
        [FormatName.Long]: 'dd.MM.yyyy HH:mm \'GMT\' XXX',
    }

    if (!formats[format]) {
        throw new Error(`Unknown format: ${format}`)
    }

    return formatter(ts * 1000, formats[format])
}

export const formatDate = ({ts, format = FormatName.Short, tz}: {
    ts: number,
    format?: FormatName
    tz?: string,
}) => {
    return tz ? formatDateTz({ts, format, tz}) : formatDateDefault({ts, format});
}

const formatUrlTitle = (title: string, maxUrlTitleLength: number) => {
    return title.length > maxUrlTitleLength ? title.substring(0, maxUrlTitleLength) + '...' : title;
}

function parseAndValidateLinks(text: string, validDomains: string[], maxUrlTitleLength = 50) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const parsedText = text.replace(urlRegex, (url) => {
        try {
            const parsedUrl = new URL(url);
            if (validDomains.some(d => parsedUrl.hostname.includes(d)) && ['http:', 'https:'].includes(parsedUrl.protocol)) {
                return `<a href="${url}">${formatUrlTitle(url, maxUrlTitleLength)}</a>`;
            } else {
                return '[Link removed]';
            }
        } catch (e) {
            return url;
        }
    });

    return DOMPurify.sanitize(parsedText, { ALLOWED_TAGS: ['a'] });
}

export function sanitizeCommentText(text: string) {
    const sanitizedText = DOMPurify.sanitize(text);
    const withLinks = parseAndValidateLinks(sanitizedText, ['sandbox.casehub.pro', 'casehub.pro']);

    return withLinks;
}

export const prepareText = (text?: string) => (text || '').split('\n').filter(Boolean);
export const prepareAnchorId = (id?: string) => {
    if (!id) {
        return;
    }

    if (id.substring(0, 1) === '#') {
        return id.substring(1);
    }

    return id;
};

export function getTimeToStart(seconds: number) {
    const futureDate = new Date(Date.now() + seconds * 1000);
    return `${formatDistanceToNow(futureDate)} to start`;
}

export function formatRelativeDate(date: number) {
    return formatDistanceToNow(date * 1000, { addSuffix: true });
}
