import React from "react";
import App from 'next/app';
import "../styles/global.scss";
import "../styles/tailwind.css";
import "keen-slider/keen-slider.min.css"
import 'react-tippy/dist/tippy.css'
import {AuthProvider} from "../util/auth";
import {BreakpointProvider} from "../util/breakpoints.js";
import wrapper from '../store/store';
import CurrentUserStoreSync from '../CurrentUserStoreSync';
import { TrackingProvider } from '../context/tracking';
import {FacebookPixel} from '../components/FacebookPixel'
import {MouseFlow} from '../components/MouseFlow';
import {GtmProvider} from '../components/GtmProvider';
import { Provider } from 'react-wrap-balancer'
import RootLayout from '../components/layout/Root';
import {ThemeProvider} from '../components/theme-provider';
import {ModeToggle} from '../components/mode-toggle';

const queries = {
    sm: '(max-width: 576px)',
    smAbove: '(min-width: 576.8px)',

    md: '(max-width: 768px)',
    mdAbove: '(min-width: 768.8px)',

    lg: '(max-width: 992px)',
    lgAbove: '(min-width: 992.8px)',

    xl: '(max-width: 1200px)',
    xlAbove: '(min-width: 1200.8px)',

    xxl: '(min-width: 1920px)',
}

class MyApp extends App {
    render() {
        const {Component, pageProps} = this.props;

        return (
            <>
                <GtmProvider />
                <Provider>
                    <BreakpointProvider queries={queries}>
                        <AuthProvider user={pageProps.user || false}>
                            <CurrentUserStoreSync />
                            <FacebookPixel />
                            <MouseFlow />
                            <TrackingProvider>
                                <PageContent pageProps={pageProps} Component={Component} />
                            </TrackingProvider>
                        </AuthProvider>
                    </BreakpointProvider>
                </Provider>
            </>
        );
    }
}

const PageContent = ({Component, pageProps}) => {
    const getLayout = Component.getLayout || ((page) => {
        return page;
    })
    
    return (
        <RootLayout pageProps={pageProps}>
            {/*<ThemeProvider attribute="class" defaultTheme="system" enableSystem>*/}
                {/*<ModeToggle />*/}
                {getLayout(
                    <Component {...pageProps} />
                )}
            {/*</ThemeProvider>*/}
        </RootLayout>
    )
}

export default wrapper.withRedux(MyApp);
