import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {
    addToolToBookmarks,
    addToolToFavorites,
    commentToolboxTool,
    deleteToolFromBookmarks,
    deleteToolFromFavorites,
    fetchToolboxesForCategory, fetchToolboxesForExpertise
} from './thunks';

const toolsAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.created - b.created,
})

const initialState = toolsAdapter.getInitialState({});

const toolsSlice = createSlice({
    name: 'tools',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {
        [deleteToolFromFavorites.fulfilled]: (state, action) => {
            const { toolId } = action.meta.arg;
            
            if (action.payload.status === 'SUCCESS') {
                toolsAdapter.updateOne(state, {
                    id: toolId,
                    changes: {
                        user_favorite: false
                    }
                })
                
            }
        },
        [deleteToolFromBookmarks.fulfilled]: (state, action) => {
            const { toolId } = action.meta.arg;
            
            if (action.payload.status === 'SUCCESS') {
                toolsAdapter.updateOne(state, {
                    id: toolId,
                    changes: {
                        user_bookmark: false
                    }
                })
                
            }
        },
        [addToolToBookmarks.fulfilled]: (state, action) => {
            const { toolId } = action.meta.arg;
            
            if (action.payload.status === 'SUCCESS') {
                toolsAdapter.updateOne(state, {
                    id: toolId,
                    changes: {
                        user_bookmark: true
                    }
                })
                
            }
        },
        [addToolToFavorites.fulfilled]: (state, action) => {
            const { toolId } = action.meta.arg;
            
            if (action.payload.status === 'SUCCESS') {
                toolsAdapter.updateOne(state, {
                    id: toolId,
                    changes: {
                        user_favorite: true
                    }
                })
                
            }
        },
        [fetchToolboxesForCategory.fulfilled]: (state, action) => {
            if (action.payload.tools) {
                toolsAdapter.upsertMany(state, action.payload.tools)
            }
        },
        [fetchToolboxesForExpertise.fulfilled]: (state, action) => {
            if (action.payload.tools) {
                toolsAdapter.upsertMany(state, action.payload.tools)
            }
        },
        [commentToolboxTool.fulfilled]: (state, action) => {
            const { toolId } = action.meta.arg;
            const commentId = action.payload.id;
            const existingTool = state.entities[toolId];
            const existingComments = existingTool.comments || [];

            if (commentId) {
                existingComments.unshift(commentId);
                toolsAdapter.updateOne(state, {
                    id: toolId,
                    changes: {
                        comments: existingComments
                    }
                })
            }
        },
    },
});

export const {
    selectAll: selectAllTools,
    selectById: selectToolById,
    selectIds: selectToolIds,
    selectEntities: selectToolEntities,
} = toolsAdapter.getSelectors((state) => state.tools);

export default toolsSlice.reducer
