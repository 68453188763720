import {
    configureStore,
    getDefaultMiddleware
} from '@reduxjs/toolkit'

import { createWrapper } from 'next-redux-wrapper';

const devMode = process.env.NODE_ENV === 'development';

import slice from './slices';

const setupStore = (context) => {
    return configureStore({
        reducer: slice,
        middleware: [...getDefaultMiddleware()],
        devTools: devMode,
    });
};

const makeStore = (context) => setupStore(context);

export const wrapper = createWrapper(makeStore, {
    debug: false && devMode,
});

export default wrapper;
