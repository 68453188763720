import { schema } from "normalizr";

export const KeyWindowsEntity = new schema.Entity('keyWindows');
export const CategoryEntity = new schema.Entity('categories');
export const CompanyEntity = new schema.Entity('companies');
export const VideoEntity = new schema.Entity('videos');
export const ExpertiseEntity = new schema.Entity('expertises');

export const UserEntity = new schema.Entity('users', {
    company: CompanyEntity
});

export const CommentEntity = new schema.Entity('comments', {
    user: UserEntity
});

export const CaseEntity = new schema.Entity('cases', {
    user: UserEntity,
    comments: [CommentEntity],
    category: CategoryEntity,
    likes: [UserEntity],
    categories: [CategoryEntity]
});

export const ToolEntity = new schema.Entity('tools', {
    video: VideoEntity,
    comments: [CommentEntity],
});

export const ToolboxEntity = new schema.Entity('toolboxes', {
    tools: [ToolEntity],
    comments: [CommentEntity],
    video: VideoEntity
});


