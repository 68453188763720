import { GraduationCap, User, Settings } from "lucide-react"
import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {SubscriptionStatuses, useAuth} from '@/util/auth';
import React from 'react';
import {UserAvatar} from '@/components/UserAvatar';
import {useRouter} from 'next/router';
import {useLastSelfAssessment} from '@/util/hooks/useGetResultsAndSuggestionsSuggestions';
import {FormatName, formatName} from '@/util/format';
import Link from 'next/link';



export function UserNav() {
    const auth = useAuth();
    const user = auth.user;
    const router = useRouter();
    const [progress] = useLastSelfAssessment();

    if (!user) {
        return null;
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-10 w-10 rounded-full">
                    <UserAvatar userId={user?.id} clickable={false} className="h-10 w-10"/>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent  align="end" forceMount>
                <DropdownMenuLabel className="font-normal gap-4 flex flex-col">
                    <div className="flex gap-4 items-center">
                        <UserAvatar userId={user?.id} className="h-16 w-16"/>
                        {!!user ? (
                            <div>
                                <p className="text-base font-medium leading-none">
                                    {formatName(user, FormatName.Long)}
                                </p>
                                <p className="text-xs text-muted-foreground">{user.email}</p>
                            </div>
                        ) : null}
                    </div>
                    <div>
                        <Link href="/user/dashboard" passHref>
                            <Button asChild variant="secondary" size="sm" className="w-full">
                                <a>My home</a>
                            </Button>
                        </Link>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator className="mx-0"/>
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => {
                        return router.push('/user/profile/subscription');
                    }}>
                        <SubscriptionInfo />
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator className="mx-0" />
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => {
                        return router.push('/user/profile');
                    }}>
                        <User className="mr-2 h-4 w-4" />
                        <span>Profile</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => {
                        return router.push('/user/profile/account');
                    }}>
                        <Settings className="mr-2 h-4 w-4" />
                        <span>Account</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
                <DropdownMenuSeparator className="mx-0" />
                <DropdownMenuItem onClick={() => {
                    return auth.signout();
                }}>
                    <span>Log out</span>
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

const SubscriptionInfo = () => {
    const auth = useAuth();
    const user = auth.user;
    const subscription = user?.subscription;

    if (!subscription) {
        return null;
    }

    const hasActiveSubscription = auth?.user?.hasActiveSubscription;
    const isTrialSubscriptionActive = auth?.user?.isTrialSubscriptionActive;
    const status = hasActiveSubscription ? SubscriptionStatuses.ACTIVE : SubscriptionStatuses.NONE;
    const subscriptionEndDate = subscription?.is_trial ? subscription.end_time : subscription.next_payment_time;
    const tz = user?.timezone;
    const trialAvailable = !!auth?.user?.trialAvailable;
    const futureSubscription = user?.futureSubscription;
    const isTrialExpired = !trialAvailable && !subscription && !futureSubscription;

    let text = null;

    if (isTrialExpired) {
        text = 'Expired';
    }

    if (isTrialSubscriptionActive) {
        text = 'Trial';
    }

    if (hasActiveSubscription) {
        text = `${subscription?.product_name}`;
    }

    if (text) {
        return (
            <div className="flex gap-2 justify-between items-center w-full">
                <p className="text-sm">
                    Subscription
                </p>
                <p className="text-xs leading-none text-muted-foreground">
                    {text}
                </p>
            </div>
        )
    }

    return null
}
