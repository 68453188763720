import {useEffect} from 'react';
import {GtmID} from '../config';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: GtmID
};

export const GtmProvider = () => {

    useEffect(() => {
        if (tagManagerArgs.gtmId) {
            TagManager.initialize(tagManagerArgs)
        }
    }, []);

    return null;
}
