import {createSlice} from '@reduxjs/toolkit'
import {getDefaultTimezone, setUserTimezone} from '../util/timezone';

const initialState = { value: getDefaultTimezone() }

const timezoneSlice = createSlice({
    name: 'timezone',
    initialState,
    reducers: {
        setTimezone(state, action) {
            state.value = action.payload;
            setUserTimezone(action.payload);
        },
    },
});

export const selectTimezone = (state) => {
    //TODO move timezone to user settings
    return getDefaultTimezone();

    // return state.timezone.value;
};

export const { setTimezone } = timezoneSlice.actions

export default timezoneSlice.reducer
