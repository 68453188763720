import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth} from '@/util/auth';
import {fetchExpertises} from '@/features/thunks';
import {selectAllExpertises} from '@/features/expertisesSlice';
import {Badge} from '@/components/ui/badge';
import { Toaster } from "@/components/ui/toaster"

interface SettingsLayoutProps {
    children: React.ReactNode,
    pageProps: any,
}

export default function RootLayout({ children, pageProps }: SettingsLayoutProps) {
    const {content = {}} = pageProps;
    const {additional = {}} = content;
    const [toolboxContent, setToolboxContent] = useState([]);
    const dispatch = useDispatch();
    const auth = useAuth();
    const currentSubscription = auth?.user?.subscription;

    useEffect(() => {
        dispatch(fetchExpertises());
    }, [dispatch]);

    const expertises = useSelector(state => selectAllExpertises(state));

    useEffect(() => {
        setToolboxContent(buildMenuFromExpertises(expertises));
    }, [expertises])

    const menuItems = [
        {
            id: 'live_sessions',
            name: 'Group sessions',
            children: [
                {
                    id: 'schedule',
                    name: 'Timetable',
                    description: 'Find the right session for you and register now',
                    href: '/schedule'
                },
                {
                    id: 'new_cases',
                    name: 'New cases',
                    href: '/new-cases',
                    description: 'Read new leadership cases and add your own'
                },
                {
                    id: 'closed_cases',
                    name: 'Case challenges',
                    href: '/closed-cases',
                    description: 'Offer your solution to previous cases',
                    footerContent: !currentSubscription ? (<div className="flex mt-1 items-center space-x-1" >
                        <Badge variant="secondary" className="bg-teal-200">free</Badge>
                        <span>Includes free content</span>
                    </div>) : null
                }
            ]},
        {id: 'toolbox', name: 'Toolbox', children: buildToolboxLinks(toolboxContent, !!currentSubscription)},
        // {id: 'pricing', name: 'Membership', href: '/pricing'},
        // {id: 'faq', name: 'FAQ', href: '/content/faq'},
    ].filter(Boolean);

    return (
        <>
            <div className="page-wrapper" id="page-wrap">
                <Navbar menuItems={menuItems} />

                <div className="min-h-[100vh]">
                    {children}
                </div>

                <Footer
                    contacts={additional.contacts}
                    links1={additional.links_1}
                    links2={additional.links_2}
                    links3={additional.links_3}
                    links4={additional.links_4}
                    links5={additional.links_5}
                    socialLinks={additional.social_links}
                />
            </div>
            <Toaster />
        </>
    )
}

const buildMenuFromExpertises = (expertises) => {
    const result = expertises.reduce((acc, e) => {
        if (!acc[e.expertise_group.id]) {
            acc[e.expertise_group.id] = {
                id: e.expertise_group.id,
                name: e.expertise_group.title,
                hasPublicContent: e.has_public_tool,
                children: []
            }
        }
        acc[e.expertise_group.id].children.push({
            id: e.id,
            name: e.title,
            hasPublicContent: e.has_public_tool,
            children: []
        });
        return acc;
    }, {});

    return Object.values(result);
}

const buildToolboxLinks = (items, hideHasPublicContentLabels, level = 0) => {
    if (!items) {
        return [];
    }

    return items.map((it) => {
        const isClickable = level > 0;

        return ({
            id: it.id,
            name: isClickable && !hideHasPublicContentLabels && it.hasPublicContent ? (<span className="flex items-center space-x-2"><span>{it.name}</span> <Badge variant="secondary" className="bg-teal-200">Free</Badge></span>) : it.name,
            href: `/toolbox/${it.id}`,
            description: it.description,
            disabled: !isClickable,
            children: it?.children?.length ? buildToolboxLinks(it.children, hideHasPublicContentLabels, level + 1) : undefined
        })
    })
}

